.wrapper {
  background-color: #f9f9fa;
}

.wrapper_gray {
  background-color: #f9f9fa;
}

.wrapper_white {
  background-color: #fff;
}

.gridWrapper {
  padding: 64px 0;
}

.gridContainer {
  max-width: 1200px;
  padding: 0 24px;
  margin: 0 auto;
}
