@import '../../styles/variables';
@import '../../styles/responsive';

.root {
  background-image: url('/images/cover-image.jpg');
  background-position: center;
  background-size: cover;
  padding: 64px 0;
  min-height: 600px;
}

.coverText {
  color: #fff;
  text-align: center;
  padding: 0 20px;
}

.title {
  color: inherit;
  font-weight: bold;
  max-width: 670px;
  margin: 0 auto;
  font-size: 38px;
  letter-spacing: -1.5px;
  line-height: 46px;
  @include responsive(tablet) {
    line-height: 64px;
    font-size: 56px;
    letter-spacing: -2.5px;
  }
}

.subTitle {
  color: inherit;
  font-size: 16px;
  max-width: 700px;
  margin: 24px auto 48px;
  font-weight: normal;
  @include responsive(tablet) {
    line-height: 28px;
    font-size: 20px;
    
  }
}

.actions {
  text-align: center;
}

.action {
  color: #fff;
  margin: 0 15px;
}
