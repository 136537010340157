.activityCardContainer {
  height: 100%;

  :global{

    .ant-card-meta-title{
      text-align: center;
      font-weight: 600;
      font-size: 18px;
      line-height: 26px;
    }

    .ant-card-meta-description{
      text-align: center;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;

      .ant-typography{
        color: var(--gray-8);
      }
    }
  }

  .activityCardViewButton{
    display: flex;
    justify-content: center;
    font-size: 16px;
    line-height: 24px;
    gap: 8px;
    padding: 16px 16px 0;
    color: var(--blue-6);

    .activityCardIcon{
      position: relative;
      top: 1px;
    }

    &:hover {
      color: var(--blue-3);

      .activityCardIcon{
        transition: transform 0.2s;
        transform: translateX(3px);
      }
      
    }
  }
}