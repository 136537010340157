@import '../../../styles/variables';
@import '../../../styles/components/textTypography';
@import '../../../styles/responsive';

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  overflow: hidden;
  background-color: #fff;

  @include responsive(tablet) {
    flex-direction: row;
  }

  .container__content {
    margin: 64px 24px 24px 24px;

    @include responsive(tablet) {
      margin: 64px 24px;
    }

    @include responsive(mobile-large) {
      max-width: 348px;
    }

    @include responsive(pc-small) {
      max-width: 466px;
      margin-right: 100px;
    }

    @include responsive(pc) {
      max-width: 564px;
      margin-left: 122px;
      margin-right: auto;
    }

    @include responsive(pc-large) {
      max-width: 764px;
      margin-left: 155px;
    }

    .content__title {
      color: $text-blue-dark-light;
      margin-bottom: 24px;

      @include textTypography(Inter, 38px, normal, 600, 46px, -1.5px, left);

      @include responsive(pc-small) {
        font-size: 46px;
        line-height: 54px;
        letter-spacing: -2px;
      }
    }

    .content__text {
      color: $text-blue-dark-lightest;
      margin-bottom: 40px;

      @include textTypography(Inter, 18px, normal, 400, 26px, -0.3px, left);
    }

    .content__button {
      background-color: $primary;
      width: fit-content;
      padding: initial 16px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      color: white;

      @include textTypography(Inter, 16px, normal, 500, 24px, 0, center);

      svg {
        margin-left: 6px;
      }

      @include responsive(pc-small) {
        width: 270px;
      }
    }
  }

  .container__image {
    width: 100%;

    @include responsive(tablet) {
      width: initial;
    }

    img {
      width: 100%;

      @include responsive(tablet) {
        height: 491px;
        width: 704px;
        margin-right: -331px;
      }

      @include responsive(pc-small) {
        height: 430px;
        width: 627px;
        margin-right: -224px;
      }

      @include responsive(pc) {
        margin-right: -199px;
      }
    }
  }
}
