.sectionWrapper{
  max-width: 1600px;
}

.carouselWrapper{
  position: relative;
}

.actionsNav{
  position: absolute;
  top: 50%;
  z-index: 2;
  left: 50%;
  width: 100%;
  transform: translate(-50% , -50%);
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  pointer-events: none;
}

.itemNavArrow{
  pointer-events: all;
}

.itemNavArrowLeft{
  transform: translateX(-15px);
}

.itemNavArrowRight{
  transform: translateX(15px);
}