.info {
  color: var(--gray-8);
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;

  p {
    margin: 0;
    padding: 0;

    svg {
      width: 28px;
      height: 28px;
      fill: var(--gray-8);
    }
  }

  font-family: Inter,sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}
