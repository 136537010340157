.sectionWrapper {
  text-align: center;
  padding-bottom: 40px;
}

.title {
  font-size: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
  color: var(--grey-9);
  font-weight: 600;
}

.badge {
  margin-left: 24px;
  display: inline-block;
  background-color: var(--blue-6);
  border-radius: 4px;
  padding: 1px 8px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  color: #fff;
  text-align: left;
}

.description {
  font-size: 18px;
  color: var(--grey-8);

  a {
    color: var(--blue-6);
    border-bottom: 1px solid var(--blue-6);
  }
}
