.wrapper {
  max-width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 1px 2px rgb(8 24 35 / 6%), 0 1px 3px rgb(8 24 35 / 10%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.tag{
  border-radius: 36px;
  border: 1px solid var(--grey-9);
  padding: 4px 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  margin-bottom: 5px;
}

.cover {
  width: 100%;
  max-height: 317px;
  overflow: hidden;
}

.dataWrapper {
  padding: 24px;
}

.categories{
  display: flex;
  flex-wrap: wrap;
}

.title {
  margin: 16px 0;
  font-size: 24px;
  color: var(--grey-9);
}

.excerpt {
  font-size: 18px;
  line-height: 26px;
  color: var(--grey-8);
}

.metaSeparator {
  margin: 0 8px;
  display: inline-block;
  width: 6px;
  height: 6px;
  background-color: var(--grey-7);
  border-radius: 50%;
}

.link{
  height: 100%;
  display: block;
  overflow: visible;
  padding: 5px;
}

.image{
  width: 100%;
  object-fit: cover;
}