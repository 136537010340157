@import '../styles/responsive';

.videoModal {
  width: 90vw;
  height: 450px;

  iframe {
    height: 100%;
    width: 100%;
  }

  @include responsive(mobile-large) {
    width: 500px;
  }

  @include responsive(pc-small) {
    width: 758px;
  }

  :global{

    .ant-modal-content {
      background-color: transparent;
      height: 100%;
      width: 100%;
    }
  
    .ant-modal-body {
      padding: 0;
      height: 100%;
      width: 100%;
    }
  }
}
