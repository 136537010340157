@import '../../../styles/variables';
@import '../../../styles/components/textTypography';
@import '../../../styles/responsive';

.container {
  padding: 80px 0;
  background-color: white;

  .container__content {
    max-width: 956px;
    padding: 0 16px;
    margin: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;

    @include responsive(mobile-large) {
      padding: 0 24px;
    }

    .content__badge {
      text-transform: uppercase;
      color: white;
      background-color: $primary;
      display: inline-block;
      margin: auto;
      padding: 1px 8px;
      border-radius: 4px;
      margin-bottom: 8px;

      @include textTypography(Inter, 12px, normal, 400, 20px, 0, center);
    }

    .content__title {
      color: $text-blue-dark-light;
      margin-bottom: 24px;

      @include textTypography(Inter, 38px, normal, 600, 46px, -1.5px, center);
    }

    .content__text {
      color: $text-blue-dark-lightest;

      @include textTypography(Inter, 18px, normal, 400, 26px, -0.3px, center);
    }
  }

  .container__button {
    background-color: $primary;
    width: fit-content;
    padding: 0 16px;
    margin: auto;
    margin-top: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    color: white;

    @include textTypography(Inter, 16px, normal, 500, 24px, 0, center);

    svg {
      margin-left: 6px;
    }

    @include responsive(pc-large) {
      width: 270px;
    }
  }
}
