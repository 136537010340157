// .makerCardWrapper {
//   background-color: pink;
// }

.title{
  color: var(--gray-9);
  transition: all 0.3s;
}

.icon{
  opacity: 0;
  transform: translateX(-5px) scale(0.9);
  transition: all 0.3s;
  font-size: 18px;
}

.card{
  border-radius: 5px;

  :global{

    .ant-card-body{
      padding: 16px;
    }
  }

  :hover{

    .title,.icon{
      color: var(--blue-6);
      transition: all 0.3s;
    }

    .icon{
      opacity: 10;
      transform: translateX(0) scale(1);
    }
  }

}

.image{
  text-align: center;
  object-fit: scale-down;
}

.titleWrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;
}

.moreCardWrapper{

  .titleWrapper{
    display: block;
    text-align: center;
    margin: 0;

    h3{
      margin: 0;
    }
  }
}