@import '../../styles/variables';
@import '../../styles/components/textTypography';
@import '../../styles/responsive';

.card {
  max-width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 1px 2px rgb(8 24 35 / 6%), 0 1px 3px rgb(8 24 35 / 10%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 17px;
  margin-bottom: 16px;

}

.cardImage {
  height: 176px;
  overflow: hidden;
  position: relative;
  background-color: #f9f9fa;
  background-image: url('/images/boat.svg');
  background-size: 137px;
  background-position: center;
  background-repeat: no-repeat;

  @include responsive(mobile-large) {
    height: 322px;
  }

  @include responsive(tablet) {
    height: 213px;
  }

  @include responsive(pc-small) {
    height: 183px;
  }

  @include responsive(pc) {
    height: 226px;
  }

  @include responsive(pc-large) {
    height: 208px;
  }

  
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.cardShare {
  position: absolute;
  bottom: 16px;
  right: 16px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;

  span {
    z-index: 2;
  }

  &::after {
    content: '';
    z-index: 0;
    position: absolute;
    width: 32px;
    height: 32px;
    opacity: 0.65;
    background-color: white;
  }
}

.cardWrapper {
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  flex-shrink: 1;

  .cardContent {

    .contentTitle {
      color: $text-blue-dark-light;

      @include textTypography(Inter, 16px, normal, 600, 24px, 0, left);
    }

    .contentText {
      color: $text-blue-dark-lightest;
      margin-top: 8px;

      @include textTypography(Inter, 14px, normal, 400, 22px, 0, left);
    }
  }

  .cardUser {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    .userInitials {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      border: 1px solid $purple;
      background-color: $purple-light;
      color: $primary;
      text-transform: uppercase;
      margin-right: 8px;

      @include textTypography(Inter, 14px, normal, 400, 22px, 0, center);
    }
  }

  .cardFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .footerLeft {
      color: $text-blue-dark-lightest;

      svg {
        color: #acaeb7;
      }
    }

    .footerRight {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;

      li {
        color: $text-blue-dark-lightest;
        padding-left: 8px;

        svg {
          color: #acaeb7;
          width: 14px;
        }
      }
    }
  }
}

.cartLinks {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  list-style: none;
  padding: 0;
  margin: 0;
  border-top: 1px solid #e1e3e5;

  .cartLink {
    text-align: center;
    border-right: 1px solid #e1e3e5;
    color: $text-blue-dark-lightest;

    @include textTypography(Inter, 14px, normal, 400, 22px, 0, center);

    &:last-child {
      border-right: none;
    }

    a {
      padding: 8px 0;
      width: 100%;
      display: block;
    }
  }
}
