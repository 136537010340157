/* stylelint-disable selector-class-pattern */
@import './responsive';
@import './variables';

.wrapper {
  background-color: #f9f9fa;
}

.boatsGridWrapper_gray {
  background-color: #f9f9fa;
}

.boatsGridWrapper_white {
  background-color: #fff;
}

.boatsGridWrapper {
  padding: 64px 0;
}

.boatsGridContainer {
  max-width: 1200px;
  padding: 0 24px;
  margin: 0 auto;
}

.boatColItem {
  margin-bottom: 24px;
}

.boat_card_card {
  margin-bottom: 24px;
}

.boatsGridActionWrapper {
  text-align: center;
  margin-top: 40px;
}

.SectionImage_Wrapper {
  position: relative;

  // height: 540px;
}

.SectionImage_Cover {
  width: 100%;
}

.SectionImage_PlayBtn {
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  background-color: transparent;
  border: none;

  &:hover{
    cursor: pointer;
  }
}

.SectionImage {
  position: relative;
}

.SectionImage_ActionWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;

  h4 {
    font-size: 24px;
    font-weight: 600;
  }

  ul {
    display: flex;
    flex-basis: 400px;
    list-style: none;
    margin: 0;

    li {
      text-align: center;
      padding: 0;
      margin: 0;
      flex-basis: auto;
      flex-grow: 1;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        height: 30px;
      }

      span {
        margin-top: 10px;
        display: block;
      }
    }
  }
}

.SectionImage_HintText {
  text-align: center;
  color: $grey-7;
  margin-top: 16px;
}
