@import '../../styles/variables';
@import '../../styles/responsive';

.root {
  background-color: $medium-blue-1;
  padding: 64px 0;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
}

.image,
.content {
  flex-basis: 50;
  width: 50%;
  flex-grow: 0;
}

.image {
  border-radius: 0 4px 4px 0;
  box-shadow: 0 25px 50px rgb(8 24 35 / 25%);
}

.content {
  background-color: #fff;
  background-image: url('/images/home/person-searching.svg');
  background-repeat: no-repeat;
  background-position: top right;
  border-radius: 4px;
  padding: 64px;
  padding-right: 150px;
  margin: 0 24px;
  box-shadow: 0 10px 15px rgb(8 24 35 / 10%), 0 4px 6px rgb(8 24 35 / 5%);
}

.title {
  font-size: 38px;
  color: $grey-9;
  line-height: 46px;
  margin-bottom: 32px;
}

.description {
  font-size: 18px;
  color: $grey-8;
  line-height: 28px;
}

@media ( max-width: $tablet ) {

  .root {
    padding: 64px 24px;
  }

  .row {
    flex-direction: column;
  }

  .image,
  .content {
    width: 100%;
    flex-basis: 100%;
  }

  .image {
    box-shadow: none;
    overflow: hidden;
    border-radius: 4px;
    margin-bottom: 24px;
    object-fit: cover;
  }

  .content {
    padding: 48px;
  }

  .title {
    max-width: 310px;
    font-size: 32px;
  }
}
